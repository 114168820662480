import axios from 'axios';
//testing internal
axios.defaults.baseURL = "http://localhost/onlinews/api/"
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
axios.defaults.headers.common['Api-Key'] = "CODEX@123";
axios.defaults.headers.common['Content-Type'] = "application/json"
axios.defaults.headers.common['Token-Auth'] = localStorage.getItem('mtoken_auth');


//axios.defaults.headers.common['Token-Auth'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJoYXNoX2htYWMtc2hhMjU2In0=.eyJicm93c2VyIjoiRGVmYXVsdCBCcm93c2VyIDAiLCJwbGF0Zm9ybSI6IiIsImlwX2FkZHJlc3MiOiI6OjEiLCJkZXZpY2VfaWQiOiIiLCJkZXZpY2VfbW9kZWwiOiIiLCJ0b2tlbl9pZCI6IldZWXNsS3R0eHJSOEVKYkhFTHpzQnlOR1NzSlZUdnRtSmxtRkhaeWkiLCJ0b2tlbl9leHBfZGF0ZSI6IjIwMjEtMDUtMDgifQ==.uNjV97NZBYq+Yc1v/muyW270BlSYbPXpNuKi2Qfx2O4=";
//console.log("local storage",localStorage.getItem('mtoken_auth'));
//console.log("axios Token-Auth",axios.defaults.headers.common['Token-Auth']);

//testing external
// axios.defaults.baseURL = "https://ws.aim-apps.com/api/"
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
// axios.defaults.headers.common['Api-Key'] = "stgplatformapikey";
// //axios.defaults.headers.common['Token-Auth'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJoYXNoX2htYWMtc2hhMjU2In0=.eyJicm93c2VyIjoiRGVmYXVsdCBCcm93c2VyIDAiLCJwbGF0Zm9ybSI6IiIsImlwX2FkZHJlc3MiOiI6OjEiLCJkZXZpY2VfaWQiOiIiLCJkZXZpY2VfbW9kZWwiOiIiLCJ0b2tlbl9pZCI6IldZWXNsS3R0eHJSOEVKYkhFTHpzQnlOR1NzSlZUdnRtSmxtRkhaeWkiLCJ0b2tlbl9leHBfZGF0ZSI6IjIwMjEtMDUtMDgifQ==.uNjV97NZBYq+Yc1v/muyW270BlSYbPXpNuKi2Qfx2O4=";
// axios.defaults.headers.common['Content-Type'] = "application/json"
// axios.defaults.headers.common['Token-Auth'] = localStorage.getItem('mtoken_auth');

//production external
axios.defaults.baseURL = "https://ws.aim-apps.com/api/"
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
axios.defaults.headers.common['Api-Key'] = "AimSekatarakyat@2021";
axios.defaults.headers.common['Content-Type'] = "application/json"
axios.defaults.headers.common['Token-Auth'] = localStorage.getItem('mtoken_auth');
